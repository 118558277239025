import React from 'react';
import styled from 'styled-components';
import Layout from '../shared/components/Layouts/Inner';
import Content from '../shared/components/Content';
import SEO from '../shared/components/SEO';

import ContainerFixed from '../shared/components/Layouts/ContainerFixed';

import Hero from '../shared/components/Hero/index';
import HeroImage from '../shared/images/banners/placeholder.jpg';
import Block from '../shared/components/Block/index';
import Section from '../shared/components/Section';
import PressReviews from '../shared/components/PressReviews';

import Scott from '../shared/images/headshots/Content/scott.jpg';
import Kerry from '../shared/images/headshots/Content/kerry.jpg';
import Team from '../shared/images/headshots/team.jpg';
import { StyledButtonLink } from '../shared/components/Button';
import Testimonials from '../shared/components/Testimonial/Testimonials';

const StyledImg = styled.img`
  @media (max-width: 900px) {
    display: none;
  }
`;

const AboutPage = () => (
  <Layout darkMode url="about-us">
    <SEO title="About Us" />
    <Hero image={HeroImage} showReviews hideServicesBtn heading="Team Maidupnorth">
      <p>
        By booking a Maidupnorth clean you are choosing a family run company which is passionate
        about providing high end, affordable, customer focused and user friendly services.
      </p>
    </Hero>
    <PressReviews />
    <ContainerFixed>
      <Content>
        <div>
          <h2>Who, what and where is Maidupnorth?</h2>
          <p>
            Maidupnorth are based in Newcastle Upon Tyne and offer regular cleaning, deep cleaning
            and end of tenancy cleaning services throughout the North East.
          </p>
          <p>
            Established on January 18th 2016 Maidupnorth has fastly became recognised as a reputable
            premium cleaning company catering to both commercial and residential clients.
          </p>
          <p>
            Mother and son team Kerry and Scott believe in offering cleaning services for all
            members of the community and have created a transparent user friendly company that
            believes in quality, understanding, flexibility and professionalism.
          </p>
          <p>
            Based in the Ouseburn Valley company headquarters are easily accessible from Gateshead,
            North Tyneside and Newcastle.
          </p>
        </div>
      </Content>
      <Content>
        <Block alignCenter>
          <div>
            <h2>Kerry Patton</h2>
            <p>
              Mum to 3 strapping lads who have all been an influential part of Maidupnorth`s journey
              Kerry is a natural Motherhen figure. A relatively bedded in `adopted Geordie` Kerry
              now considers the Toon her home.
            </p>

            <p>
              When not popping around the North East she can be found in The Lake District bagging
              Wainwrights.
            </p>
            <p>
              A strong sense of adventure leads her on a trail of jolly jaunts when time allows.
              From an advocacy background she believes in offering her extended Maidupnorth family
              upskilling opportunities and flexible work hours to suit around family/ lifestyle
              commitments.
            </p>
            <p>Family is key!</p>
          </div>
          <StyledImg src={Kerry} alt="Kerry" />
        </Block>
      </Content>
      <Content>
        <Block alignCenter>
          <StyledImg src={Scott} alt="Scott" />
          <div>
            <h2>Scott McMullan</h2>
            <p>
              Climbing and surfing are two of Scott's cooler hobbies, house plant collecting is one
              of his less adventurous past times.
            </p>
            <p>
              Hailing originally from Northern Ireland and now living between Berlin and Newcastle
              Upon Tyne. His thick irish accent still baffles Germans and English speakers alike.
            </p>
          </div>
        </Block>
      </Content>
    </ContainerFixed>
    <Section containedText>
      <h2>Meet the team!</h2>
      <p>
        Our band of Mighty Maids are such wonderful Maidupnorth team players and take great pride in
        all of their domestic and commercial cleans. Attention to detail is paramount and our
        cleaners strive for perfection by going that extra mile to provide a 5 star rated clean at
        every cleaning appointment.
      </p>
    </Section>
    <ContainerFixed>
      <Content>
        <Block alignCenter>
          <div>
            <h2>What does it take to be a Maidupnorth Family Member?</h2>
            <ul>
              <li>Friendly and enthusiastic people person</li>
              <li>Passionate about cleaning</li>
              <li>Excellent customer service skills</li>
              <li>Punctuality and reliability</li>
              <li>Eye for detail</li>
              <li>Trustworthiness</li>
              <li>Proven cleaning professional track record</li>
              <li>Willingness to upskill</li>
              <li>Team player</li>
              <li>Vetted and checked</li>
            </ul>
          </div>
          <img src={Team} alt="Team" />
        </Block>
      </Content>
    </ContainerFixed>
    <Section>
      <h2>Building and celebrating strong foundations…</h2>

      <p>We are a very happy and friendly bunch at Maidupnorth.</p>

      <p>
        Being a maid is a very physically demanding job, not for the faint hearted. We could brag
        about our cleaning awards but what we truly are proud of is our amazing team who come to
        work with a hearty smile, can do attitude and strive for excellent customer service showing
        professionalism at all times.
      </p>

      <p>
        Team building is paramount to us. Based in the Ouseburn Valley we have attended numerous
        local activities and events such as shows, dancing the night away, had a few cocktails,
        tried our hand at ghetto golf, pizza parties, the odd sociable or two and eaten in quite a
        few restaurants.
      </p>

      <p>
        Knowing our team members not just as work colleagues is important to us to build a caring,
        cleaning, community that can grow positively.
      </p>
      <br />
      <h2>Join our Team</h2>
      <p>
        We are always looking to expand our amazing team and welcome applicants who share the same
        beliefs, want to enhance their skill sets, want to join an equal opportunity company that
        believes in providing flexible opportunities that suit your lifestyle and nurtures your
        personal work growth.
      </p>
      <StyledButtonLink to="/contact">Contact Us</StyledButtonLink>
    </Section>
    <ContainerFixed>
      <Testimonials />
    </ContainerFixed>
  </Layout>
);

export default AboutPage;
